import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import { graphql, Link } from "gatsby"
import remark from 'remark'
import remarkHTML from 'remark-html'

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({
  data, 
  pageContext
}) {
  const [isShowingNextPrevButtons, setIsShowingNextPrevButtons] = React.useState(false);

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark;
  const { next, prev } = pageContext;

  const bannerUrl = frontmatter.mediaRoot + frontmatter.banner;

  let lastScrollPos = 0;

  const handleScroll = (event) => {
    let currentScroll = document.documentElement.scrollTop;

    if (currentScroll > lastScrollPos) {
      setIsShowingNextPrevButtons(false);
    } else {
      setIsShowingNextPrevButtons(true);
    }

    lastScrollPos = currentScroll; 
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // cleanup this component
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout>

      <Banner title={frontmatter.title} button="Get in touch." imageUrl={bannerUrl}>
      </Banner>

      <div className={`previous-next-buttons ${isShowingNextPrevButtons ? "showing" : ""}`}>
        <div className="link-with-icon">
          <Link to={prev.frontmatter.slug} className="previous-button link-navy-to-peach transition">
            <div className="pointing-icon-container transition left">
              <img src="/left-arrow-icon-peach.svg" className="pointing-icon navy transition"></img>
              <img src="/left-arrow-icon-navy.svg" className="pointing-icon peach transition"></img>
            </div>Previous
          </Link>
        </div>
        <div className="link-with-icon">
          <Link to={next.frontmatter.slug} className="next-button link-navy-to-peach transition">Next
            <div className="pointing-icon-container transition">
              <img src="/right-arrow-icon-peach.svg" className="pointing-icon navy transition"></img>
              <img src="/right-arrow-icon-navy.svg" className="pointing-icon peach transition"></img>
            </div>
          </Link>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="columns project-details-section">
            <div className="column">
              <div className="content markdown project-description" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.description)}} />
            </div>
            <div className="column service-list">
              <div className="service-list-items">
                <div className="text-with-sparkles">
                  <p className="is-size-4 is-size-5-mobile">Skills I brought to the&nbsp;table</p>
                  <img src="/sparkles-icon.svg" className="sparkles-icon"></img>
                </div>         
                <ul className="is-size-5">
                  {frontmatter.services.map((serviceObject, index) => (
                    <li key={index}>{serviceObject}</li>
                  ))}
                </ul>
                {frontmatter.websiteUrl &&
                <div className="link-with-icon">
                  <Link to={frontmatter.websiteUrl} className="is-size-5 link-navy-to-peach transition">View website
                    <div className="pointing-icon-container transition">
                      <img src="/pointing-right-icon-navy.svg" className="pointing-icon navy transition"></img>
                      <img src="/pointing-right-icon-peach.svg" className="pointing-icon peach transition"></img>
                    </div>
                  </Link>
                </div>
                }
              </div>
            </div>
          </div>

          {frontmatter.media.map((mediaListObject, index) => (
            <div key={index} className="columns is-multiline">
            {mediaListObject.list.map((mediaObject, index) => (
              <div key={index} className={`${mediaObject.classes} column`}>
                {mediaObject.type == "image" &&
                  <img src={frontmatter.mediaRoot + mediaObject.source} />
                }
                {mediaObject.type == "video" &&
                  <video autoplay="autoplay" muted="muted" loop="loop">
                    <source src={frontmatter.mediaRoot + mediaObject.source} type="video/mp4" />
                  </video>
                }
                {mediaObject.type == "vimeo" &&
                  <div className="vimeo">
                    <iframe src="https://player.vimeo.com/video/505578688" width="640" height="360" frameborder="1" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  </div>
                }
              </div>
            ))}
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        mediaRoot
        description
        services
        banner
        websiteUrl
        media {
          list {
            type
            source
            classes
          }
        }
      }
    }
  }
`